























































import {Component, Vue} from 'vue-property-decorator'
import {getCourseCoachByIdAsync, updateCourseAsync} from '@/api/course'
import {CourseDto} from '@/dto/CourseDto';
import {modifyOrders} from '@/api/courseOrder'

@Component({})
export default class CourseOpen extends Vue {
  private actionShow = false

  private courseInfo: CourseDto = {
    coaches: [],
    courseImgs: [],
    category: 0,
    courseTime: '',
    endDateTime: '',
    helpline: '',
    id: '',
    limitNum: 0,
    name: '',
    openingDate: '',
    originalPrice: 0,
    price: 0,
    remark: '',
    startDateTime: '',
    buyNum: 0
  }

  private actions = [{name: '已开课'}, {name: '未开课'}]

  get courseId() {
    return this.$route.query.courseId;
  }

  private async activated() {
    await this.getInfo()
  }

  private async getInfo() {
    if (this.courseId) {
      this.courseInfo = await getCourseCoachByIdAsync(this.courseId)
    }
  }

  private handleCaoZuo() {
    this.actionShow = true
  }

  private async handleSelect(action: any, index: number) {
    let state = -1 // 未开课
    if (action.name === '已开课') {
      state = 1
    }

    if (this.courseId) {
      await updateCourseAsync(this.courseId, state)
      await this.$router.push('/course/index')
    }
  }

  private async handleSign(id: string) {
    await modifyOrders(id)
    this.$toast('签到成功')
    await this.getInfo()
  }
}
